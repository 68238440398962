import { useAxios } from '@vueuse/integrations/useAxios'
import axios from 'axios'

export const updatePass = (data?: any) => {
  const url = import.meta.env.VITE_APP_ADMIN_API + 'api/users/updatePass'
  return useAxios(url, { method: 'POST', data }, axios)
}

export const logout = (data?: any) => {
  const url = import.meta.env.VITE_APP_ADMIN_API + 'auth/logout'
  return useAxios(url, { method: 'DELETE', data }, axios)
}
