import type { HttpResponse } from 'peace-library'
import type { UserInfo } from '@/types'
import { useAxios } from '@vueuse/integrations/useAxios'
import axios from 'axios'

export const login = () => {
  const url = import.meta.env.VITE_APP_ADMIN_API + 'auth/login'
  return useAxios<HttpResponse<UserInfo>>(url, { method: 'POST' }, axios, { immediate: false })
}

export const sendCode = () => {
  const url = import.meta.env.VITE_APP_ADMIN_API + 'auth/sendCode'
  return useAxios<HttpResponse<UserInfo>>(url, { method: 'GET' }, axios, { immediate: false })
}
