import { system } from '@/config'
import { cloneDeep } from 'lodash'
import { getLocalStorage, setLocalStorage } from 'peace-library'

const getUrlConfiguration = (configuration: typeof system) => {
  const regex = /[?&]([^=#]+)=([^&#]*)/g

  const urlConfiguration: { [x: string]: string } = {}

  window.location.href.replace(regex, (_match, key, value): any => {
    if (decodeURIComponent(key) in configuration) {
      urlConfiguration[decodeURIComponent(key)] = decodeURIComponent(value)
    }
  })

  return urlConfiguration
}

const getStorageConfiguration = () => {
  return getLocalStorage('SYSTEM_CONFIGURATION')
}

const setStorageConfiguration = (configuration: typeof system) => {
  setLocalStorage('SYSTEM_CONFIGURATION', configuration)
}

const setWindowConfiguration = (configuration: typeof system) => {
  Object.defineProperty(window, 'configuration', {
    value: configuration,
    writable: false,
    enumerable: false,
    configurable: false
  })
}

/**
 * 获取系统运行所需的配置信息
 *
 * URL > Storage > Config
 */
export default async function () {
  window.configuration = system

  const systemConfig = cloneDeep(system)
  const storageConfig = getStorageConfiguration()
  const urlConfig = getUrlConfiguration(system)
  const configuration = Object.assign({}, systemConfig, storageConfig, urlConfig)

  setStorageConfiguration(configuration)
  setWindowConfiguration(configuration)

  console.log(
    `%c ${configuration.appName} %c ${configuration.appVersion} `,
    'background: #35495e; padding: 2px; border-radius: 3px 0 0 3px; color: #fff',
    'background: #41b883; padding: 2px; border-radius: 0 3px 3px 0; color: #fff'
  )

  return configuration
}
