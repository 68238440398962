import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { layout } from '@/config'

export const useLayoutStore = defineStore('layout', () => {
  const minWidth = ref(layout.minWidth)
  const mode = ref(layout.mode)

  const fixedHeaderAndTab = ref(layout.fixedHeaderAndTab)

  // header
  const headerHeight = ref(layout.headerHeight)

  // tab
  const tabVisible = ref(layout.tabVisible)
  const tabHeight = ref(layout.tabHeight)

  // side
  const siderVisible = ref(layout.siderVisible)
  const siderWidth = ref(layout.siderWidth)
  const siderCollapse = ref(layout.siderCollapse)
  const siderCollapsedWidth = ref(layout.siderCollapsedWidth)

  // footer
  const footerVisible = ref(layout.footerVisible)

  return {
    minWidth,
    mode,
    fixedHeaderAndTab,
    headerHeight,
    tabVisible,
    tabHeight,
    siderVisible,
    siderWidth,
    siderCollapse,
    siderCollapsedWidth,
    footerVisible
  }
})
