import type { UserInfo, Menu } from '@/types'
import { getGlobalLocalStorage, setGlobalLocalStorage, getSessionStorage, setSessionStorage } from 'peace-library'

export const REMEMBER_ME = 'REMEMBER_ME'
export const USER_INFO = 'USER_INFO'
export const USER_MENUS = 'USER_MENUS'

export const isLogin = () => {
  return getGlobalLocalStorage(USER_INFO)
}

export const setRememberMe = () => {
  setGlobalLocalStorage(REMEMBER_ME, true)
}

export const getRememberMe = () => {
  return getGlobalLocalStorage(REMEMBER_ME)
}

export const getUserInfo = (): UserInfo => {
  return getGlobalLocalStorage(USER_INFO)
}

export const setUserInfo = (userInfo: UserInfo) => {
  setGlobalLocalStorage(USER_INFO, userInfo)
}

export const getMenus = (): Menu[] => {
  return getSessionStorage(USER_MENUS)
}

export const setMenus = (menus: Menu[]) => {
  setSessionStorage(USER_MENUS, menus)
}

export const removeUserInfo = () => {
  window.localStorage.clear()
  window.sessionStorage.clear()
}
