/*
 * @Date: 2022-03-16
 * @Description: Loading for axios
 *
 * dependencies naive-ui loading bar
 */

import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import axios from 'axios'

/**
 * @description: axios loading hooks
 * @return {void}
 */
export const useAxiosLoading = (): void => {
  let pendingCount = 0

  // set request interceptors
  axios.interceptors.request.use(
    (config) => {
      pendingCount++
      window.$loadingBar?.start()

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  // set response interceptors
  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      pendingCount--
      if (pendingCount <= 0) {
        window.$loadingBar?.finish()
      }

      return response
    },
    (error) => {
      pendingCount--
      if (pendingCount <= 0) {
        window.$loadingBar?.finish()
      } else {
        window.$loadingBar?.error()
      }

      return Promise.reject(error)
    }
  )
}
