// import type { App } from 'vue'
import { useAxios, useAxiosLoading, useDayjs } from 'peace-library'
import { isLogin, getUserInfo } from '@/utils'
// export boot install function
// async is optional
export default async function ({ app, router }: any) {
  // use dayjs hooks
  useDayjs()

  // use axios hooks
  // set interceptors and set token
  useAxios(isLogin() && getUserInfo().token, isLogin() && getUserInfo().user.user.tenantId)
  // use loading hooks
  useAxiosLoading()
}
