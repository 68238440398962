import * as echarts from 'echarts/core'

// 引入 Echarts 主题
import macarons from './echarts.macarons.json'
import chalk from './echarts.chalk.json'

// 引入 ECharts 各模块来减小打包体积
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart, BarChart, LineChart, TreemapChart, ScatterChart } from 'echarts/charts'
import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  DatasetComponent,
  DataZoomComponent,
  SingleAxisComponent,
  MarkLineComponent
} from 'echarts/components'
// export boot install function
// async is optional
export default async function ({ app }: any) {
  // echarts
  echarts.registerTheme('light', macarons.theme)
  echarts.registerTheme('dark', chalk.theme)
  echarts.use([
    CanvasRenderer,

    MarkLineComponent,
    PieChart,
    BarChart,
    LineChart,
    TreemapChart,
    GridComponent,
    TooltipComponent,
    LegendComponent,
    TitleComponent,
    DataZoomComponent,
    DatasetComponent,
    SingleAxisComponent,
    ScatterChart
  ])
}
