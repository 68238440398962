<template>
  <div class="flex flex-col justify-center w-full h-full py-12 page-container">
    <div class="flex items-center justify-center my-8">
      <img class="h-1/2" src="./assets/images/404.svg" alt="" />
    </div>
    <div class="text-center mb-8">
      <div class="text-lg">
        <span>抱歉，你访问的地址不存在，{{ sendTime }}秒后跳转首页，</span>
        <span class="text-blue-500 cursor-pointer" @click="goHome">立即返回</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
const router = useRouter()
function goHome() {
  router.replace('/')
}
const setTiem = setInterval(() => {
  if (sendTime.value <= 1) {
    goHome()
  } else {
    sendTime.value--
  }
}, 1000)

const sendTime: any = ref(3)

onBeforeUnmount(() => {
  clearInterval(setTiem)
})
</script>
