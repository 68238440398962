import type { User, UserInfo } from '@/types'
import { getUserInfo } from '@/utils'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useUserStore = defineStore('user', () => {
  const token = ref('')
  const user = ref<User>()

  const setUserInfo = (userInfo: UserInfo) => {
    token.value = userInfo.token
    user.value = userInfo.user
  }

  const startup = () => {
    const user = getUserInfo()

    if (user) {
      setUserInfo(user)
    }
  }

  return {
    token,
    user,
    startup,
    setUserInfo
  }
})
