// import type { App } from 'vue'

// FIXME:
// scrollbar for windows
// windows 丑陋的滚动条
import './../assets/css/scrollbar.css'

// support tailwind css
import './../assets/css/tailwind.css'

//公共样式
import './../assets/css/common.css'
// supoort tailwind dynamic theme
import { tailwindThemeOverrides } from '@/config'

export const applyTailwindTheme = (): void => {
  const styleEl = document.createElement('style')
  document.head.appendChild(styleEl)

  const cssList = Object.keys(tailwindThemeOverrides).map((themeProperty) => {
    return Object.keys(tailwindThemeOverrides[themeProperty]).map((colorProperty) => {
      const propertyValue = tailwindThemeOverrides[themeProperty][colorProperty]
      return `${themeProperty}-${colorProperty}: ${propertyValue}`
    })
  })

  styleEl.sheet?.insertRule(`*, ::before, ::after {${cssList.flat().join(';')}}`, styleEl.sheet.cssRules.length)
}

// export boot install function
// async is optional
export default async function ({ app, router, store, configuration }: any) {
  // fix naive ui with tailwind.css
  const meta = document.createElement('meta')
  meta.name = 'naive-ui-style'
  document.head.appendChild(meta)

  // use tailwind dynamic theme
  applyTailwindTheme()
}
